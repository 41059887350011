import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import withRouter from "../hooks/withRouter";
import { actionCreators } from '../store/auth/actions';
import { AuthStatusEnum } from '../store/auth/types';
import { injectIntl } from 'react-intl';
import Loader from "../components/loader/";

//https://stackoverflow.com/questions/26882177/react-js-inline-style-best-practices

class Signin extends Component {
    static displayName = Signin.name;

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            retryCount: 0,
            isLinked: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e && e.preventDefault();

        if (this.props.status === AuthStatusEnum.PROCESS) {
            return;
        }

        const { username, password } = this.state;
        if (username && password) {
            if (this.props.passwordOpt === 1)
                this.signIn(username, password.toUpperCase());
            else if (this.props.passwordOpt === 2)
                this.signIn(username, password.toLowerCase());
            else
                this.signIn(username, password);
        }
    }

    componentDidMount() {
        const search = this.props.location.search;
        const username = new URLSearchParams(search).get("username");
        const password = new URLSearchParams(search).get("password");

        if (username && password) {
            this.setState({ isLinked: true, username, password });
            this.initialiseAutoRefresh();
        }
    }

    initialiseAutoRefresh() {
        if (!this.interval) {
            this.interval = setInterval(() => {
                if (this.props.appReady) {
                    clearInterval(this.interval);
                    this.handleSubmit(undefined);
                }
            }, 100);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status !== this.props.status && this.props.status === AuthStatusEnum.SUCCESS) {
            window.location.reload();
        }
    }

    render() {
        const { username, password, isLinked } = this.state;
        const isDisabled = (!(username && password)) || this.props.status === AuthStatusEnum.PROCESS;

        if (!this.props.appReady || isLinked) {
            return <Loader />;
        }

        if (this.props.status === AuthStatusEnum.SUCCESS) {
            return <Navigate to="/" />;
        }
        const signIn = this.props.intl.formatMessage({ id: 'signIn' });
        const errorContactingServer = this.props.intl.formatMessage({ id: 'errorContactingServer' });
        const invalidUsername = this.props.intl.formatMessage({ id: 'invalidUsername' });
        const usernameS = this.props.intl.formatMessage({ id: 'username' });
        const usernameRequired = this.props.intl.formatMessage({ id: 'usernameRequired' });
        const passwordS = this.props.intl.formatMessage({ id: 'password' });
        const passwordRequired = this.props.intl.formatMessage({ id: 'passwordRequired' });
        const passwordMinLength = this.props.intl.formatMessage({ id: 'passwordMinLength' });
        const rightsReserved = this.props.intl.formatMessage({ id: 'rightsReserved' });
        return (
            <div className="px-3 container-fluid">
                <div className="min-vh-100 row">
                    <div className="d-flex align-items-center col-md-8 col-lg-6 col-xl-5">
                        <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
                            <div className="signPos">
                                <h2>{signIn}</h2>
                            </div>
                            {this.props.status === AuthStatusEnum.FATAL &&
                                <div className="alert alert-danger" role="alert">
                                    {errorContactingServer}
                                </div>
                            }
                            {this.props.status === AuthStatusEnum.FAIL &&
                                <div className="alert alert-danger" role="alert">
                                    {invalidUsername}
                                </div>
                            }

                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="loginUsername" className="form-label">{usernameS}</label>
                                    <input
                                        name="username"
                                        id="loginUsername"
                                        placeholder={usernameS}
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                        value={username}
                                        onChange={this.handleChange} />
                                    <div className="invalid-feedback">{usernameRequired}</div>
                                </div>
                                <div className="mb-4 form-group">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="loginPassword" className="form-label">{passwordS}</label>
                                        </div>
                                        <div className="col-auto">
                                            &nbsp;
                                        </div>
                                    </div>
                                    <input
                                        name="password"
                                        id="loginPassword"
                                        placeholder={passwordS}
                                        className="form-control"
                                        type="password"
                                        autoComplete="off"
                                        value={password}
                                        onChange={this.handleChange} />
                                    <div className="invalid-feedback">{passwordRequired}</div>
                                    <div className="invalid-feedback">{passwordMinLength}</div>
                                </div>
                                <button className="btn btn-primary w-100" type="submit" disabled={isDisabled}>
                                    {   this.props.status === AuthStatusEnum.PROCESS &&
                                        <span class="spinner-border spinner-border-sm spinner-custom" role="status" aria-hidden="true"></span>
                                    }
                                    {signIn}
                                </button>
                                <hr className="my-4" />
                                <div className="text-gray-600 text-center mb-0">
                                    {this.props.companyName} &copy; {new Date().getFullYear()} {rightsReserved}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="d-none d-md-block col-md-4 col-lg-6 col-xl-7">
                        <div className="bg-cover h-100 mr-n3"></div>
                    </div>
                </div>
            </div>
        );
    }

    async signIn(username, password) {
        this.props.login({ username, password, retryPassword: ( this.props.retryPassword || 1) });
    }
}

function mapStateToProps(state) {
    return {
        status: state.auth.status,
        appReady: state.config.appReady,
        passwordOpt: state.config.passwordOpt,
        retryPassword: state.config.retryPassword,
        companyName: state.config.brandName
    };
}

const mapDispatchToProps = {
    login: actionCreators.login
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Signin)));
