import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { actionCreators as AuthActions } from '../store/auth/actions';
import { updateThemeColor, getCurrentThemeColor } from "../themeUtils";
import { returnCurrentLanguage } from '../index';
import { changeSkin, returnSkin } from '../index';
import { injectIntl } from 'react-intl';


class CustomerSettings extends Component {

    handleButton1 = () => {
        this.props.setSkin(1);
        changeSkin('red');
        this.props.toggleSettingsMenu();
    }

    handleButton2 = () => {
        this.props.setSkin(0);
        changeSkin('');
        this.props.toggleSettingsMenu();
    }

    handleButton3 = () => {
        this.props.setSkin(2);
        changeSkin('velocity');
        this.handleSetColor('velocity');
        this.props.toggleSettingsMenu();
    }

    handleSetColor = (color) => {
        const currentConfig = this.props.context && this.props.context.configurations;
        const skin = currentConfig && currentConfig.skin && currentConfig.skin.skinId ? currentConfig.skin.skinId : "";
        updateThemeColor(color, returnSkin(), skin);

        if (color === 'light') {
            this.props.setColor(0);
        } else if (color === 'yellow') {
            this.props.setColor(1);
        } else if (color === 'red') {
            this.props.setColor(2);
        } else if (color === 'blue') {
            this.props.setColor(3);
        } else if (color === 'velocity') {
            this.props.setColor(4);
        }

        this.forceUpdate();
        this.props.toggleSettingsMenu();
    }


    handleSetLanguage = (language) => {
        this.props.setLanguage(language === 'en' ? 0 : 1);
        this.props.toggleSettingsMenu();
    }

    render() {
        const currentColor = getCurrentThemeColor();
        const currentLanguage = returnCurrentLanguage();

        const isLightDisabled = currentColor === 'light';
        const isYellowDisabled = currentColor === 'yellow';
        const isRedDisabled = currentColor === 'red';
        const isBlueDisabled = currentColor === 'blue';
        const isVelocityDisabled = currentColor === 'velocity';
        
        const selectThemeColor = this.props.intl.formatMessage({ id: 'selectThemeColor' });
        const light = this.props.intl.formatMessage({ id: 'light' });
        const yellow = this.props.intl.formatMessage({ id: 'yellow' });
        const red = this.props.intl.formatMessage({ id: 'red' });
        const blue = this.props.intl.formatMessage({ id: 'blue' });

        const enDisabled = currentLanguage === "en";
        const esDisabled = currentLanguage === "es";
        const selectLanguage = this.props.intl.formatMessage({ id: 'selectLanguage' });
        const spanish = this.props.intl.formatMessage({ id: 'spanish' });
        const english = this.props.intl.formatMessage({ id: 'english' });
        const generalSettings = this.props.intl.formatMessage({ id: 'generalSettings' });

        const currentSkin = returnSkin();
        const skinEnabled = currentSkin === "red";
        const skinDesabled = currentSkin === "";
        const thirdSkinEnabled = currentSkin === "velocity";

        const selectSkin = this.props.intl.formatMessage({ id: 'selectSkin' });
        const firstSkin = this.props.intl.formatMessage({ id: 'firstSkin' });
        const secondSkin = this.props.intl.formatMessage({ id: 'secondSkin' });
        const thirdSkin = this.props.intl.formatMessage({ id: 'thirdSkin' });
        const currentConfig = this.props.context.configurations.find(config => config.showSkin === "true");
        const firstConfig = this.props.context.configurations.find(config => config.firstSkin === "true");
        const secondConfig = this.props.context.configurations.find(config => config.secondSkin === "true");
        const thirdConfig = this.props.context.configurations.find(config => config.thirdSkin === "true");


        return (
            <div>
                <div className={`modal fade ${this.props.isOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: this.props.isOpen ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-aside fixed-right" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{generalSettings}</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={this.props.toggleSettingsMenu}></button>
                                
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">{selectLanguage}</h5>
                                        <div className="d-flex flex-column">
                                            <button className="btn btn-primary mb-2" onClick={() => this.handleSetLanguage('es')} disabled={esDisabled}>
                                                {spanish}
                                            </button>

                                            <button className="btn btn-primary mb-2" onClick={() => this.handleSetLanguage('en')} disabled={enDisabled}>
                                                {english}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{selectThemeColor}</h5>
                                        <div className="row">
                                            <div className="row">
                                                <div className="col-6 d-flex justify-content-center">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <button className="btn btn-primary mb-2" onClick={() => this.handleSetColor('light')} disabled={isLightDisabled}>
                                                            {light}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-6 d-flex justify-content-center align-items-center">
                                                    <img src="/Light.png" alt="Imagen 1" className="img-fluid mb-2" />
                                                </div>
                                                <div className="col-6 d-flex justify-content-center">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <button className="btn btn-primary mb-2" onClick={() => this.handleSetColor('yellow')} disabled={isYellowDisabled}>
                                                            {yellow}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-6 d-flex justify-content-center align-items-center">
                                                    <img src="/Yellow.png" alt="Imagen 2" className="img-fluid mb-2" />
                                                </div>
                                                <div className="col-6 d-flex justify-content-center">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <button className="btn btn-primary mb-2" onClick={() => this.handleSetColor('red')} disabled={isRedDisabled}>
                                                            {red}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-6 d-flex justify-content-center align-items-center">
                                                    <img src="/Red.png" alt="Imagen 3" className="img-fluid mb-2" />
                                                </div>
                                                <div className="col-6 d-flex justify-content-center">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <button className="btn btn-primary mb-2" onClick={() => this.handleSetColor('blue')} disabled={isBlueDisabled}>
                                                            {blue}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-6 d-flex justify-content-center align-items-center">
                                                    <img src="/Blue.png" alt="Imagen 3" className="img-fluid mb-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {currentConfig && (
                                    <div className="card-body">
                                            <div className="card">
                                                <h5 className="card-title">{selectSkin}</h5>
                                                {firstConfig && (
                                                    <div>
                                                        <button
                                                            className="btn btn-primary mb-2 w-100"
                                                            onClick={this.handleButton2}
                                                            disabled={skinDesabled} 
                                                        >
                                                            {firstSkin}
                                                        </button>
                                                    </div>
                                                )}
                                                {secondConfig && (
                                                    <div>
                                                        <button
                                                            className="btn btn-primary mb-2 w-100"
                                                            onClick={this.handleButton1}
                                                            disabled={skinEnabled}
                                                        >
                                                            {secondSkin}
                                                        </button>
                                                    </div>
                                                )}
                                                {thirdConfig && (
                                                    <div>
                                                        <button
                                                            className="btn btn-primary mb-2 w-100"
                                                            onClick={this.handleButton3}
                                                            disabled={thirdSkinEnabled}
                                                        >
                                                            {thirdSkin}
                                                        </button>
                                                    </div>
                                                )}
                                        </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.isOpen && <div className="modal-backdrop fade show"></div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        context: state.config
    }
}

const mapDispatchToProps = {
    setTeaserStyle: BetslipActions.setTeaserStyle,
    setLanguage: AuthActions.setLanguage,
    logout: AuthActions.resetState,
    setSkin: AuthActions.setSkin,
    setColor: AuthActions.setColor,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerSettings));
