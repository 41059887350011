export function updateThemeColor(newColor, skinId, skin) {
    const invalidColorsForSkin3 = ["light", "yellow", "red", "blue"];
    const invalidColorsForSkin1And2 = ["velocity", "velocityDark", "velocityBlue"];
    if (skin === "velocity" && skinId === 3) {
        skinId = 2;
    } else if (skin === "red" && skinId === 3) {
        skinId = 1;
    } else if (skin === "" && skinId === 3) {
        skinId = 0;
    }
    if (skinId === 2 && invalidColorsForSkin3.includes(newColor)) {
        newColor = "velocity";
    }
    else if ((skinId === 0 || skinId === 1) && invalidColorsForSkin1And2.includes(newColor)) {
        newColor = "light";
    }
    document.getElementsByTagName('html')[0].setAttribute('data-bs-theme', newColor);
}


export function getCurrentThemeColor() {
    return document.getElementsByTagName('html')[0].getAttribute('data-bs-theme');
}

