import { BetslipActionType } from './types';
import { actionCreators as actionBalance } from '../balance/actions';
import { BalanceActionType } from '../balance/types';

export const actionCreators = {
    addItem: (item) => {
        return ({
            payload: { item },
            type: BetslipActionType.ADD,
        });
    },
    removeItem: (item) => {
        return ({
            payload: item,
            type: BetslipActionType.REMOVE,
        });
    },
    moveItemUpward: (item) => {
        return ({
            payload: item,
            type: BetslipActionType.MOVE_UP,
        });
    },
    toggleBetslip: () => {
        return ({
            type: BetslipActionType.SET_OPEN_STATE
        });
    },
    toggleReadback: () => {
        return ({
            type: BetslipActionType.READBACK_OPEN_STATE
        });
    },
    toggleSportMenu: () => {
        return ({
            type: BetslipActionType.SET_MENU_STATE
        });
    },
    toggleItemMsg: (item) => {
        return ({
            type: BetslipActionType.PICK_MSG,
            payload: item
        });
    },
    toggleFreeplay: (item) => {
        return ({
            type: BetslipActionType.TOGGLE_FREEPLAY
        });
    },
    toggleOpenWager: (item) => {
        return ({
            type: BetslipActionType.SET_OPENBET,
            payload: item
        });
    },
    setTeaserStyle: (teaserId) => async (dispatch, getState) => {
        dispatch({ payload: teaserId, type: BetslipActionType.TEASER_STYLE });
        await actionCreators.handleCompileBetslip(dispatch, getState);
    },
    setBetType: (betType) => async (dispatch, getState) => {
        dispatch({ payload: { betType }, type: BetslipActionType.SET_TYPE });
        await actionCreators.handleCompileBetslip(dispatch, getState);
    },
    toggleItem: (item, token) => async (dispatch, getState) => {
        const betslip = getState().betslip;
        const byId = betslip.byId;

        if (byId[item.eventId] === undefined) {
            dispatch({ payload: item, type: BetslipActionType.ADD });
        } else {
            dispatch({ payload: item, type: BetslipActionType.REMOVE });
        }

        await actionCreators.handleCompileBetslip(dispatch, getState);
    },
    clear: () => {
        return ({ type: BetslipActionType.CLEAR });
    },
    setItemStake: (item, info) => async (dispatch, getState) => {
        let payload = {
            ...item,
            singleWin: info.win,
            singleRisk: info.risk
        };

        if (info.currentPrice) {
            payload = {
                ...payload,
                currentPrice: info.currentPrice,
                currentBase: info.currentBase,
                initialPrice: info.currentPrice,
                initialBase: info.currentBase,
                bidPrice: info.currentPrice,
                bidBase: 0
            };
        }
        else if (info.bidPrice) {
            payload = {
                ...payload,
                bidPrice: (info.bidPrice || item.bidPrice),
                bidBase: (info.bidBase || item.bidBase)
            };
        }

        dispatch({ payload, type: BetslipActionType.SET_ITEM_STAKE });

        if (getState().betslip.betType > 0) {
            await actionCreators.handleCompileBetslip(dispatch, getState);
        }
    },
    setBettingOpts: (opts) => async (dispatch, getState) => {
        const betslip = getState().betslip;
        dispatch({ payload: opts, type: BetslipActionType.STAKE_TYPE });
        if (betslip.betType > 0) {
            await actionCreators.handleCompileBetslip(dispatch, getState);
        }
    },
    setOpenSeatOpts: (opts) => async (dispatch, getState) => {
        dispatch({ payload: opts, type: BetslipActionType.SEAT_TYPE });
        await actionCreators.handleCompileBetslip(dispatch, getState);
    },
    setTeaserBuyOpts: (opts) => async (dispatch, getState) => {
        dispatch({  opts, type: BetslipActionType.TEASER_PICK_OPTS });
        await actionCreators.handleCompileBetslip(dispatch, getState);
    },
    sendBets: () => async (dispatch, getState) => {
        const token = getState().auth.token;
        const betslip = getState().betslip;
        dispatch({ type: BetslipActionType.PROCESSING });
        try {
            
            const betRequest = {
                BetType: betslip.betType,
                AmountType: betslip.amountType,
                Amount: betslip.amount,
                Id: betslip.teaserId,
                useFreePlay: betslip.useFreePlay,
                OpenSlots: betslip.betType === 1 ? betslip.openSlots : 0,
                SlotId: betslip.betType === 1 ? betslip.slotId : 0,
                Bets: betslip.ids.filter(id => betslip.betType > 0 || (betslip.betType === 0 && betslip.byId[id].singleWin > 0)).map(id => {
                    const bet = betslip.byId[id];
                    return {
                        Id: `${bet.eventId}_${bet.initialBase}_${bet.initialPrice}`,
                        Amount: Math.min(bet.singleRisk, bet.singleWin),
                        InitialPrice: bet.initialPrice,
                        InitialBase: bet.initialBase,
                        BidPrice: bet.bidPrice,
                        BidBase: bet.handicap > 0 ? bet.handicap : bet.bidBase
                    }
                })
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                },
                body: JSON.stringify(betRequest)
            };

            const response = await fetch(`api/v1/wagers/${betslip.betType}/send`, requestOptions);
            if (response.ok) {
                const payload = await response.json();
                if (payload.msg) {
                    dispatch({ type: BetslipActionType.BET_FAILED, payload });
                } else {
                    const keys = payload.bets.filter(b => b.ticketId).reduce(function (map, obj) {
                        const tokens = obj.ticketId.replaceAll(',', '|').split('|');
                        for (var i = 0; i < tokens.length; i++) {
                            map[+tokens[i]] = true;
                        }
                        return map;
                    }, {});

                    const readbackReply = await actionBalance.fetchReadbackUnwrapped(token, Object.keys(keys).join('_'));
                    dispatch({
                        type: BalanceActionType.REQUEST_SUCCESS,
                        payload: readbackReply.balance
                    });
                    dispatch({
                        type: BetslipActionType.BET_ACCEPTED,
                        payload: {
                            ...payload,
                            readback: readbackReply
                        }
                    });
                }
            } else {
                dispatch({ type: BetslipActionType.BET_FAILED, payload: response.statusText });
                if (response.status === 401) {
                    window.location.reload();
                }
            }
        } catch (e) {
            dispatch({ type: BetslipActionType.BET_FAILED, payload: e });
        }
    },
    fetchPickInfoUnwrapped: async (items, betslip, token) => {
        try {
            const betRequest = {
                BetType: betslip.betType,
                AmountType: betslip.amountType,
                Amount: betslip.amount,
                Id: betslip.teaserId,
                useFreePlay: betslip.useFreePlay,
                OpenSlots: betslip.betType === 1 ? betslip.openSlots : 0,
                SlotId: betslip.betType === 1 ? betslip.slotId : 0,
                Bets: items.map(item => ({
                    Id: `${item.eventId}_${item.initialBase}_${item.initialPrice}`,
                    InitialPrice: item.initialPrice,
                    InitialBase: item.initialBase,
                    BidPrice: item.bidPrice,
                    BidBase: item.handicap > 0 ? item.handicap : item.bidBase
                }))
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                },
                body: JSON.stringify(betRequest)
            };

            const response = await fetch(`api/v1/wagers/${betslip.betType}/compile`, requestOptions);
            if (response.ok) {
                return await response.json();
            } else {
                if (response.status === 401) {
                    window.location.reload();
                }
            }
        } catch (e) {
            //dispatch({ type: BalanceActionType.REQUEST_FAIL });
        }

        return null;
    },
    fetchGames: (leagueIds) => async (dispatch, getState) => {

        dispatch({ type: BetslipActionType.SELECTED_LEAGUES, payload: leagueIds });

        const token = getState().auth.token;
        const wagerId = getState().betslip.slotId;
        const idgmtyp = getState().config.idgmtyp;
        var reply = await actionCreators.fetchGamesUnwrapped(wagerId, leagueIds, token, idgmtyp);

        dispatch({ type: BetslipActionType.GAMES_LOADED, payload: reply });
    },


    fetchPersonalizedGames: (gameIds) => async (dispatch, getState) => {
        try {
            dispatch({ type: BetslipActionType.SELECTED_LEAGUES, payload: gameIds });

            const token = getState().auth.token;
            const wagerId = getState().betslip.slotId;
            const response = await actionCreators.fetchGamesPersonalizedUnwrapped(wagerId, gameIds, token);

            dispatch({ type: BetslipActionType.GAMES_LOADED, payload: response });
        } catch (error) {
            console.error('Error fetching personalized games:', error);
            dispatch({ type: BetslipActionType.BET_FAILED, error });
        }
    },


    fetchGamesUnwrapped: async (wagerId, leagueIds, token, idgmtyp) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                }
            };

            const response = await fetch(`api/v1/sports/${wagerId}/schedule?leagueIds=${leagueIds}&idHide=${idgmtyp}`, requestOptions);
            if (response.ok) {
                const dataset = await response.json();
                const reply = {
                    tree: dataset,
                    byId: {}
                };
                for (var i = 0; i < dataset.length; i++) {
                    const league = dataset[i];
                    for (var j = 0; j < league.groups.length; j++) {
                        const group = league.groups[j];
                        group.games.sort((a, b) => a.kickoff - b.kickoff);

                        for (var k = 0; k < group.games.length; k++) {
                            const fixture = group.games[k];
                            fixture.lookup = `${fixture.rot} ${fixture.vtm} ${fixture.htm}`;
                            reply.byId[fixture.id] = fixture;
                        }
                    }
                }
                return reply;
            } else {
                if (response.status === 401) {
                    window.location.reload();
                }
            }
        } catch (e) {
            //dispatch({ type: BalanceActionType.REQUEST_FAIL });
        }

        return [];
    },


    fetchGamesPersonalizedUnwrapped: async (wagerId, idGame, token) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                }
            };
            
            const response = await fetch(`api/v1/sports/${wagerId}/schedule?leagueIds=0&idGame=${idGame}`, requestOptions);
            if (response.ok) {
                const dataset = await response.json();
                const reply = {
                    tree: dataset,
                    byId: {}
                };
                for (var i = 0; i < dataset.length; i++) {
                    const league = dataset[i];
                    for (var j = 0; j < league.groups.length; j++) {
                        const group = league.groups[j];
                        group.games.sort((a, b) => a.kickoff - b.kickoff);

                        for (var k = 0; k < group.games.length; k++) {
                            const fixture = group.games[k];
                            fixture.lookup = `${fixture.rot} ${fixture.vtm} ${fixture.htm}`;
                            reply.byId[fixture.id] = fixture;
                        }
                    }
                }
                return reply;
            } else {
                if (response.status === 401) {
                    window.location.reload();
                }
            }
        } catch (e) {
            console.error("Error fetching games:", e);
        }

        return { tree: [], byId: {} };
    },

    fetchTeaserRulesUnwrapped: async (token) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                }
            };

            const response = await fetch(`api/v1/sports/0/teaserRules`, requestOptions);
            if (response.ok) {
                return await response.json();
            } else {
                if (response.status === 401) {
                    window.location.reload();
                }
            }
        } catch (e) {
            //dispatch({ type: BalanceActionType.REQUEST_FAIL });
        }

        return [];
    },
    handleCompileBetslip: async (dispatch, getState) => {
        const betslip = getState().betslip;
        const token = getState().auth.token;

        const newBetslip = { ...betslip };
        let hasAborted = false;
        if (betslip.ids.length > 0) {
            const items = [];
            for (var i = 0; i < betslip.ids.length; i++) {
                const item = betslip.byId[betslip.ids[i]];
                if (item) {
                    items.push(item);
                }
            }

            dispatch({ type: BetslipActionType.PROCESSING });
            const payload = await actionCreators.fetchPickInfoUnwrapped(items, newBetslip, token);
            if (payload) {
                for (var j = 0; payload.bets.length > 0 && j < items.length; j++) {
                    const item = items[j];
                    const id = item.eventId;
                    const bet = payload.bets.find(b => b.id.indexOf(id) === 0);
                    if (bet) {
                        dispatch({
                            payload: {
                                eventId: item.eventId,
                                limit: bet.limit,
                                offers: bet.opts,
                                tag: bet.id,
                                singleStake: payload.singleStake,
                                opts: payload.opts
                            },
                            type: BetslipActionType.PICK_OPTS
                        });
                    }
                }

                if (payload.msg) {
                    dispatch({ type: BetslipActionType.BET_FAILED, payload });
                    hasAborted = true;
                }
            }
        }

        if (!hasAborted)
            dispatch({ type: BetslipActionType.RESET_STATUS });
    }
};