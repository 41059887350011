import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { actionCreators as AuthActions } from '../store/auth/actions';
import { updateThemeColor, getCurrentThemeColor } from "../themeUtils";
import { returnCurrentLanguage } from '../index';
import { changeSkin, returnSkin } from '../index';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { faPaintRoller } from '@fortawesome/free-solid-svg-icons';


class CustomerSettings2 extends Component {
    modalRef = createRef();

    handleClickOutside = (event) => {
        if (this.props.isOpen && this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.props.toggleSettingsMenu();
        }
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    handleButton1 = () => {
        this.props.setSkin(1);
        changeSkin('red');
        const currentConfig = this.props.context && this.props.context.configurations;
        const skin = currentConfig && currentConfig.skin && currentConfig.skin.skinId ? currentConfig.skin.skinId : "";
        updateThemeColor('light', returnSkin(), skin);
        this.props.setColor(0);
        this.props.toggleSettingsMenu();
    }

    handleButton2 = () => {
        this.props.setSkin(0);
        changeSkin('');
        const currentConfig = this.props.context && this.props.context.configurations;
        const skin = currentConfig && currentConfig.skin && currentConfig.skin.skinId ? currentConfig.skin.skinId : "";
        updateThemeColor('light', returnSkin(), skin);
        this.props.setColor(0);
        this.props.toggleSettingsMenu();
    }

    handleButton3 = () => {
        this.props.setSkin(2);
        changeSkin('velocity');
        this.props.toggleSettingsMenu();
    }

    handleSetColor = (color) => {
        const currentConfig = this.props.context && this.props.context.configurations;
        const skin = currentConfig && currentConfig.skin && currentConfig.skin.skinId ? currentConfig.skin.skinId : "";
        updateThemeColor(color, returnSkin(), skin);
        console.log(color, returnSkin());
        if (color === 'light') {
            this.props.setColor(0);
        } else if (color === 'yellow') {
            this.props.setColor(1);
        } else if (color === 'red') {
            this.props.setColor(2);
        } else if (color === 'blue') {
            this.props.setColor(3);
        } else if (color === 'velocity') {
            this.props.setColor(4);
        } else if (color === "velocityBlue") {
            this.props.setColor(5);
        } else if (color === "velocityDark") {
            this.props.setColor(6);
        }

        this.forceUpdate();
        this.props.toggleSettingsMenu();
    }


    handleSetLanguage = (language) => {
        this.props.setLanguage(language === 'en' ? 0 : 1);
        this.props.toggleSettingsMenu();
    }

    handleOpenHistory = (history) => {
        console.log(history);
    }

    render() {
        const currentColor = getCurrentThemeColor();
        const currentLanguage = returnCurrentLanguage();

        const isLightDisabled = currentColor === 'light';
        const isYellowDisabled = currentColor === 'yellow';
        const isRedDisabled = currentColor === 'red';
        const isBlueDisabled = currentColor === 'blue';
        const isVelocityDisabled = currentColor === 'velocity';

        const selectThemeColor = this.props.intl.formatMessage({ id: 'selectThemeColor' });
        const light = this.props.intl.formatMessage({ id: 'light' });
        const yellow = this.props.intl.formatMessage({ id: 'yellow' });
        const red = this.props.intl.formatMessage({ id: 'red' });
        const blue = this.props.intl.formatMessage({ id: 'blue' });
        const dark = this.props.intl.formatMessage({ id: 'dark' });

        const enDisabled = currentLanguage === "en";
        const esDisabled = currentLanguage === "es";
        const selectLanguage = this.props.intl.formatMessage({ id: 'selectLanguage' });
        const spanish = this.props.intl.formatMessage({ id: 'spanish' });
        const english = this.props.intl.formatMessage({ id: 'english' });
        const generalSettings = this.props.intl.formatMessage({ id: 'generalSettings' });
        const claro = this.props.intl.formatMessage({ id: 'claro' });
        

        const currentSkin = returnSkin();
        const skinEnabled = currentSkin === "red";
        const skinDesabled = currentSkin === "";
        const thirdSkinEnabled = currentSkin === "velocity";

        const selectSection = this.props.intl.formatMessage({ id: 'selectSection' });
        const selectSkin = this.props.intl.formatMessage({ id: 'selectSkin' });
        const firstSkin = this.props.intl.formatMessage({ id: 'firstSkin' });
        const secondSkin = this.props.intl.formatMessage({ id: 'secondSkin' });
        const thirdSkin = this.props.intl.formatMessage({ id: 'thirdSkin' });
        const currentConfig = this.props.context.configurations.find(config => config.showSkin === "true");
        const firstConfig = this.props.context.configurations.find(config => config.firstSkin === "true");
        const secondConfig = this.props.context.configurations.find(config => config.secondSkin === "true");
        const thirdConfig = this.props.context.configurations.find(config => config.thirdSkin === "true");


        const settledBets = this.props.intl.formatMessage({ id: 'settledBets' });
        const unsettledBets = this.props.intl.formatMessage({ id: 'unsettledBets' });
        const products = [
            {
                label: "sports",
                path: '/',
                icon: 'home'
            },
            {
                label: "figures",
                path: '/account',
                icon: 'account_circle'
            },
            {
                label: settledBets,
                path: '/account/settled',
                icon: 'account_circle'
            },
            {
                label: unsettledBets,
                path: '/account/unsettled',
                icon: 'account_circle'
            }/*,
    {
        label: live,
        path: '/live-betting',
        icon: 'rss_feed'
    }*/
        ].filter(Boolean);

        const filteredProducts = products.filter(product =>
            product.path === '/account/settled' || product.path === '/account/unsettled'
        );
        return (
            <div>
 
                <div>
                    <div
                        className={`modal fade ${this.props.isOpen ? 'show' : ''}`}
                        tabIndex="-1"
                        role="dialog"
                        style={{ display: this.props.isOpen ? 'block' : 'none' }}
                    >
                        <div
                            className="modal-dialog modal-dialog-custom"
                            role="document"
                        >
                            <div className="modal-content modal-content-custom" ref={this.modalRef}>


                            <div className="modal-header">
                                <h5 className="modal-title">History</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={this.props.toggleSettingsMenu}></button>
                            </div>

                            <div className="modal-body">
                                <ul className="card-body">
                                    <h5 className="card-title">{selectSection}</h5>
                                    {filteredProducts.map((product, index) => (
                                        <li className="d-flex flex-column" key={index}>
                                            {product.path ? (
                                                product.opensInIframe ? (
                                                    <Link
                                                        to="/live-betting"
                                                        className="text-decoration-none py-0 nav-link"
                                                        onClick={() => {
                                                            this.handleItemClick('/live-betting', true);
                                                            this.props.toggleSettingsMenu();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faClockRotateLeft} />
                                                        <span style={{ marginLeft: '8px' }}>{product.label}</span>
                                                    </Link>
                                                ) : (
                                                        <Link to={product.path} className="nav-link" onClick={() => this.props.toggleSettingsMenu()}>
                                                            <FontAwesomeIcon icon={faClockRotateLeft} />
                                                            <span style={{ marginLeft: '8px' }}>{product.label}</span>
                                                    </Link>
                                                )
                                            ) : (
                                                <button onClick={product.onClick} className="nav-link">
                                                    {product.label}
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>

                                <div className="d-flex flex-column">
                                    <ul className="card-body">
                                        <h5 className="card-title">{selectLanguage}</h5>
                                        <div className="d-flex flex-column">
                                            <div className="text-decoration-none py-0 nav-link" onClick={() => this.handleSetLanguage('es')} disabled={esDisabled}>
                                                <FontAwesomeIcon icon={faLanguage} />
                                                <span style={{ marginLeft: '8px' }}>{spanish}</span>
                                            </div>
                                            <div className="text-decoration-none py-0 nav-link" onClick={() => this.handleSetLanguage('en')} disabled={enDisabled}>
                                                <FontAwesomeIcon icon={faLanguage} />
                                                <span style={{ marginLeft: '8px' }}>{english}</span>
                                            </div>
                                        </div>
                                    </ul>
                                    <ul className="card-body">
                                        <h5 className="card-title">{selectThemeColor}</h5>
                                        <div className="text-decoration-none py-0 nav-link" onClick={() => this.handleSetColor('velocity')}>
                                            <FontAwesomeIcon icon={faPaintRoller} />
                                            <span style={{ marginLeft: '8px' }}>{claro}</span>
                                        </div>
                                        <div className="text-decoration-none py-0 nav-link" onClick={() => this.handleSetColor('velocityBlue')}>
                                            <FontAwesomeIcon icon={faPaintRoller} />
                                            <span style={{ marginLeft: '8px' }}>{blue}</span>
                                        </div>
                                        <div className="text-decoration-none py-0 nav-link" onClick={() => this.handleSetColor('velocityDark')}>
                                            <FontAwesomeIcon icon={faPaintRoller} />
                                            <span style={{ marginLeft: '8px' }}>{dark}</span>
                                        </div>
                                    </ul>
                                    {currentConfig && (
                                        <ul className="card-body">
                                                <h5 className="card-title">{selectSkin}</h5>
                                                {firstConfig && (
                                            <div className="text-decoration-none py-0 nav-link" onClick={this.handleButton2} disabled={skinDesabled}>
                                                <FontAwesomeIcon icon={faPalette} />
                                                <span style={{ marginLeft: '8px' }}>{firstSkin}</span>
                                            </div>
                                                )}
                                                {secondConfig && (
                                            <div className="text-decoration-none py-0 nav-link" onClick={this.handleButton1} disabled={skinEnabled}>
                                                <FontAwesomeIcon icon={faPalette} />
                                                <span style={{ marginLeft: '8px' }}>{secondSkin}</span>
                                            </div>
                                                )}
                                                {thirdConfig && (
                                            <div className="text-decoration-none py-0 nav-link" onClick={this.handleButton3} disabled={thirdSkinEnabled}>
                                                <FontAwesomeIcon icon={faPalette} />
                                                <span style={{ marginLeft: '8px' }}>{thirdSkin}</span>
                                                    </div>
                                                )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.isOpen && <div className="modal-backdrop fade show"></div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        context: state.config
    }
}

const mapDispatchToProps = {
    setTeaserStyle: BetslipActions.setTeaserStyle,
    setLanguage: AuthActions.setLanguage,
    logout: AuthActions.resetState,
    setSkin: AuthActions.setSkin,
    setColor: AuthActions.setColor,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerSettings2));
