import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { AuthActionType, STORAGE_KEY } from './store/auth/types';
import { actionCreators as AuthActions } from './store/auth/actions';
import { BetslipActionType } from './store/betslip/types';

import configureStore, { Bootstrap } from './store/configureStore'
import AppRoutes from './AppRoutes';

import './assets/style/theme-default.scss';
import './assets/style/velocity-default.scss'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { IntlProvider } from 'react-intl';
import { updateThemeColor } from "./themeUtils";

const rootElement = document.getElementById('root');
const initialState = {}
const store = configureStore(initialState)


let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
let response_es = await fetch(`${baseUrl}es.json`);
let response_en = await fetch(`${baseUrl}en.json`);
let messages_es = await response_es.json();
let messages_en = await response_en.json();
let changeLanguage;
let returnCurrentLanguage;
let changeSkin;
let returnSkin;

function AppNavigation({ skin }) {
    const routes = useRoutes(AppRoutes(skin)); 
    return routes;
}

const messages = {
    es: messages_es,
    en: messages_en,
};

const App = (props) => {
    const [skin, setSkin] = useState('');
    const [language, setLanguage] = React.useState(navigator.language.split(/[-_]/)[0]);

    changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
    };

    returnCurrentLanguage = () => {
        return language;
    };

    changeSkin = (newSkin) => {
        setSkin(newSkin);
    }

    returnSkin = () => {
        return skin;
    }

    React.useEffect(() => {
        const auth = store.getState().auth;
        const config = store.getState().config;
        const domain = window.location.hostname;
        const currentConfig = config.configurations.find(config => config.url === domain);
        const skin = currentConfig && currentConfig.skin && currentConfig.skin.skinId ? currentConfig.skin.skinId : "";
        if (auth && auth.token) {
            if (auth.languageId === 1) {
                changeLanguage('es');
            } else {
                changeLanguage('en');
            }


            if (auth.colorId === 0) {
                updateThemeColor("light", auth.skinId, skin);
            } else if (auth.colorId === 1) {
                updateThemeColor("yellow", auth.skinId, skin);
            } else if (auth.colorId === 2) {
                updateThemeColor("red", auth.skinId, skin);
            } else if (auth.colorId === 3) {
                updateThemeColor("blue", auth.skinId, skin);
            } else if (auth.colorId === 4) {
                updateThemeColor("velocity", auth.skinId, skin);
            } else if (auth.colorId === 5) {
                updateThemeColor("velocityBlue", auth.skinId, skin);
            } else if (auth.colorId === 6) {
                updateThemeColor("velocityDark", auth.skinId, skin);
            }

            
            if (auth.skinId === 3) {
                changeSkin(skin);
            } else if (auth.skinId === 2) {
                changeSkin("velocity");
            } else if (auth.skinId === 1) {
                changeSkin("red");
            } else if (auth.skinId === 0) {
                changeSkin("");
            }
        }
    }, [props.data]);

    return (
        <IntlProvider locale={language} messages={messages[language]}>
            <BrowserRouter>
            <Provider store={store}>
                    <AppNavigation skin={skin} />
                </Provider>
            </BrowserRouter>
        </IntlProvider>
    );
};

const root = createRoot(rootElement);

const render = () => {
    root.render(<App/>);
};

/** 
const checkForUpdates = async () => {
    try {
        const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
        const response = await fetch(`${baseUrl}manifest.json`);
        const serverVersion = await response.json();
        const serverVersionNumber = serverVersion.version;
        let localVersion = localStorage.getItem('appVersion');

        if (localVersion !== serverVersionNumber) {
            localStorage.setItem('appVersion', serverVersion.version);
            //const skin = returnSkin();
            //changeSkin(skin);
            window.location.reload(true);
        } else {
            localStorage.setItem('appVersion', serverVersion.version);
        }
    } catch (error) {
        console.error('ERROR', error);
    }
};

setInterval(checkForUpdates, 60000);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

Bootstrap(store);

const token = localStorage.getItem(STORAGE_KEY);
const bootstrapAuth = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload = JSON.parse(token);
            store.dispatch({ type: AuthActionType.LOGIN_SUCCESS, payload });
            const info = await AuthActions.fetchInfoUnwrapped(payload.token);

            if (info) {
                store.dispatch({
                    type: BetslipActionType.BOOK_STAKE,
                    payload: {
                        minWager: info.minWager,
                        maxWager: info.maxWager,
                        flags: info.flags,
                        currencySymbol: info.currencySymbol,
                        languageId: info.languageId,
                        skinId: info.skinId,
                        colorId: info.colorId,
                        message: info.message
                    }
                });
            } else {
                store.dispatch({ type: AuthActionType.LOGOUT });
            }

            resolve(info);
        } catch (e) {
            reject(e)
        }
    });
};

if (token) {
    bootstrapAuth()
        .then(render)
        .catch(render);
}
else {
    render();
}

export { changeLanguage, returnCurrentLanguage, changeSkin, returnSkin};